import React from 'react'
import Footer from '../../components/Footer'
import Intro from '../../Contentful/Intro'
import Partners from '../../Contentful/Partners'
import RespondentInfo from '../../Contentful/RespondentInfo'
import SampleSizeCTA from '../../Contentful/SampleSize'
import { QueryGraphql } from '../../utils/graphQlQuery'
import richTextRenderer from '../../utils/richTextRenderer'
import { RespondentHomepageData } from '../../utils/GraphQL/respondentQueryData'
import './respondent.scss'

const RespondentHome = ({ ...props }) => {
  let data = QueryGraphql()
  const {
    allContentfulRespondentHomepage: { nodes },
  } = RespondentHomepageData()

  const respondentHomepageData = nodes[0]

  const {
    allContentfulHomepage: { nodes: homepageData },
  } = data

  const { raw: introRaw } = homepageData.filter(
    (solution) => solution.contentfulid === 'intro'
  )[0].content

  const { raw: questionRaw } = homepageData.filter(
    (solution) => solution.contentfulid === 'question'
  )[0].content

  const { raw: solutionsRaw } = homepageData.filter(
    (solution) => solution.contentfulid === 'solutions'
  )[0].content

  const { raw: sampleSizeRaw } = homepageData.filter(
    (solution) => solution.contentfulid === 'sampleSize'
  )[0].content

  return (
    <div className="home">
      <Intro data={richTextRenderer(JSON.parse(questionRaw))} path={props} />
      <Partners />
      <RespondentInfo respondentHomepageData={respondentHomepageData} />
      <SampleSizeCTA data={`Let’s represent your thoughts and needs!`} />
      <Footer />
    </div>
  )
}

export default RespondentHome
