import React from 'react'
import Home from './home'
import { useLocation } from '@reach/router'
import RespondentHome from './respondent'
import { SEO } from '../components/SEO/seo'

const App = () => {
  const location = useLocation()
  const { pathname } = location

  if (pathname.includes('respondent')) {
    return <RespondentHome path={pathname} />
  } else {
    return <Home path={pathname} />
  }
}

export default App

export const Head = () => (
  <SEO />
)