import { useStaticQuery, graphql } from 'gatsby'

export const RespondentHomepageData = () => {
  const queryData = useStaticQuery(graphql`
    query RespondentHomepage {
      allContentfulRespondentHomepage {
        nodes {
          firstSectionTitle
          firstSectionInfo
          takeSurveyInfo
          gainPointsInfo
          takeASurveyCardTitle
          takeASurveyCardDescription
          gainPointsCardTitle
          gainPointsCardDescription
          withdrawCashCardTitle
          withdrawCashCardDescription
          takeASimpleSurveyLists
          gainPointsSurveyLists
          takeSurveyTitle
          gainPointsTitle
        }
      }
    }
  `)

  return queryData
}
