import React from 'react'
import './respondentcard.scss'
import HandPenImage from '../../../images/respondent-cards/handPen.png'

export const RespondentCard = ({ title, description, image, backgroundColor }) => {
  const defaultData = {
    title: 'Take a survey',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
     Volutpat id enim ac egestas nibh ac tellus. 
     Vestibulum tempor non arcu, donec scelerisque elementum molestie volutpat elit. `,
  }
  return (
    <div className={`resp-card-container ${backgroundColor ?? `bg-secondary`} mobile-card`}>
      <h5>{title ?? defaultData.title}</h5>
      <p>{description ?? defaultData.description}</p>
      <div className="row jt-flex-end mt-10">
        <img
          src={image ?? HandPenImage}
          alt={`hand_pen_img`}
          className="respondent-card-img"
        />
      </div>
    </div>
  )
}
