import { Link } from 'gatsby'
import React, { useState } from 'react'
import { RespondentCard } from '../../components/Card/RespondentCard/RespondentCard'
import CTA from '../../components/CTA'
import GainPoints from '../../images/templates/gain_points.png'
import Take from '../../images/templates/take.png'
import PersonBikeImage from '../../images/respondent-cards/personBike.png'
import PersonDeviceImage from '../../images/respondent-cards/personDevice.png'

import './resp.scss'

const RespondentInfo = ({ respondentHomepageData }) => {
  const [active, setActive] = useState('points')

  const {
    firstSectionTitle,
    firstSectionInfo,
    takeSurveyInfo,
    gainPointsInfo,
    takeASurveyCardTitle,
    takeASurveyCardDescription,
    gainPointsCardTitle,
    gainPointsCardDescription,
    withdrawCashCardTitle,
    withdrawCashCardDescription,
    takeASimpleSurveyLists,
    gainPointsSurveyLists,
    takeSurveyTitle,
    gainPointsTitle,
  } = respondentHomepageData

  const cm = (...args) => {
    return args.filter((v) => v).join(' ')
  }

  const toggleButtonFunction = (buttonStatus) => {
    setActive(buttonStatus)
  }

  return (
    <div className="info-container">
      <div className="section-1">
        <div className="first-section-info">
          <h2>{firstSectionTitle}</h2>
        </div>
        <div className="first-section-info">
          {firstSectionInfo &&
            firstSectionInfo.map((val) => {
              return <p>{val}</p>
            })}
          {/*  <p>
            We value your opinion as we look into improving the standard of
            living. Your answers go to the consumer and market research that
            inform stakeholder decisions.
          </p>
          <p>
            Join our community of information sharers and decision makers and
            start earning rewards simply by Signing Up
          </p> */}
        </div>
      </div>
      <div className="row w-100 sp-bt col-mobile">
        <RespondentCard
          title={takeASurveyCardTitle}
          description={takeASurveyCardDescription}
        />
        <RespondentCard
          title={gainPointsCardTitle}
          description={gainPointsCardDescription}
          backgroundColor={`bg-primary`}
          image={PersonDeviceImage}
        />
        <RespondentCard
          title={withdrawCashCardTitle}
          description={withdrawCashCardDescription}
          backgroundColor={`bg-primary`}
          image={PersonBikeImage}
        />
      </div>
      <div className="section-2">
        <h2>How our platform works</h2>
        <ol className="inline_text">
          <li>Sign Up</li>
          <li>Take Surveys</li>
          <li>Earn Rewards</li>
        </ol>
        <div className="section-2-buttons">
          <Link to="#0" className="btn-large">
            <CTA
              type={`${cm(
                '',
                active !== 'take' ? 'primary-transparent' : 'primary'
              )}`}
              width="100%"
              height="30px"
              size="1em"
              onClick={() => toggleButtonFunction('take')}
            >
              Take
            </CTA>
          </Link>
          <Link to="#0" className="btn-large">
            <CTA
              width="100%"
              height="30px"
              size="1em"
              type={`${cm(
                '',
                active !== 'points' ? 'primary-transparent' : 'primary'
              )}`}
              onClick={() => toggleButtonFunction('points')}
            >
              Gain Points
            </CTA>
          </Link>
        </div>
      </div>
      <div className="section-3">
        <div
          className={`${cm(
            'buttonStatus',
            active === 'take' ? '' : 'hid-carousel'
          )} first-carousel`}
        >
          <div className="first-section-info">
            <img src={Take} alt="gain_points" className="left-image" />
          </div>
          <div className="first-section-info">
            <div className="last-sector">
              <h3>{takeSurveyTitle}</h3>

              <div className="info-status-section">
                <p>{takeSurveyInfo}</p>
                <ol className="inline_text">
                  {takeASimpleSurveyLists &&
                    takeASimpleSurveyLists.map((val, ind) => {
                      return <li key={ind}>{val}</li>
                    })}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${cm(
            'buttonStatus',
            active === 'points' ? '' : 'hid-carousel'
          )} second-carousel`}
        >
          <div className="first-section-info">
            <img src={GainPoints} alt="gain_points" className="left-image" />
          </div>
          <div className="first-section-info">
            <div className="last-sector">
              <h3>{gainPointsTitle}</h3>

              <div className="info-status-section">
                <p>{gainPointsInfo}</p>
                <ol className="inline_text">
                  {gainPointsSurveyLists &&
                    gainPointsSurveyLists.map((val, ind) => {
                      return <li key={ind}>{val}</li>
                    })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RespondentInfo
